<template>
    <div>
        <headTitle :isMain="2"/>
        <div class="news-box">
            <img class="news-img" src="../../assets/image/mobile/05-2第三个3年.png"/>
            <div class="title">第三个3年，比孚再次通过ISO9001认证</div>
            <div class="date">2022/11/04</div>
            <div class="line"></div>
            <div class="content">2022年11月3日，上海比孚信息科技有限公司再一次顺利通过了ISO9001的认证。<br/><br/>

                ISO9001证书有效期为3年，从2014年开始，这已经是我们第三个3年了。<br/><br/>

                在此认证审核的过程中，我们自上而下，从公司，部门到项目，展示了各层级的整体目标及相应的质量标准，实施方案和成果。
                根据公司惯例，根据每年质量目标达成状况，在管理评审会议上审查评估绩效，并制定下一年度的质量目标，使产品质量和服务
                质量得到持续改善而满足客户需求。专家组对公司管理体系运行情况给予充分肯定，一致同意本次审核顺利通过。<br/><br/>

                ISO9001是ISO组织颁布的影响面最广的一个管理体系标准。其质量体系文件是指导全司实施质量管理的纲领性文件，是开展各
                项管理活动的依据和行为准则。经过严格审核的国际标准化的质量管理体系认证，能够加强客户对比孚公司的信任，提高企业的
                经济效益和社会效益。<br/><br/>

                本次认证工作的顺利完成，对于公司完善管理体系，进一步提高管理水平具有重要的意义。未来，比孚将不忘初心，继续努力，
                再上征程！<br/><br/>
            </div>
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            box2ImageList:[
                {
                    index:0,
                    urlImg:require("../../assets/image/index/01-15GF整合数据分析系统.png"),
                    title:'5GF整合数据分析系统',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-5GF'
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/index/01-1数字化产线.png"),
                    title:'数字化产线', //右转是0
                    content:'打造透明、高效、稳定的数字化生产管理平台',
                    route:'/selected-line'
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/index/01-1市场渗透率分析.png"),
                    title:'市场渗透率分析',
                    content:'全球某化妆品巨头',
                    route:'/selected-marykay'
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/index/01-1智能仓储.png"),
                    title:'智能仓储',
                    content:'布局原料赋码，助力品质生产',
                    route:'/selected-warehousing'
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/index/01-1Dashboard数据实施.png"),
                    title:'Dashboard数据实施',
                    content:'全球某知名乳制品公司',
                    route:'/selected-dashboard'
                },
                {
                    index:5,
                    urlImg:require("../../assets/image/index/01-1商情诊断管理分析.png"),
                    title:'商情诊断管理分析',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-business'
                },
                {
                    index:6,
                    urlImg:require("../../assets/image/index/01-1iDAS整合数据分析系统.png"),
                    title:'iDAS整合数据分析系统', //左转是0
                    content:'韩国某知名化妆品集团',
                    route:'/selected-iDAS'
                },

            ],
            box4List:[
                {
                    index: 1,
                    title:'比孚科技和亚马逊云科技联合主办SAP上云探索及经验分享交流会',
                    date:'2022/11/30',
                    content:'2022年11月30日，比孚科技和亚马逊云科技将在上海地标建筑马勒别墅饭店，联合主办SAP上云探索及经验分享交流会。随着数字经济发展日新月异，企业转型面临多重挑战，云转型无疑成为未来发展的趋势，加上新冠疫情的影响，加速了企业上云的决策，是推进企业数字化转型的关键步伐……',
                    url:require("../../assets/image/index/5-0SAP乘云而上.png"),
                    route:'/mobile-news-detail3'
                },
                {
                    index: 2,
                    title:'第三个3年，比孚再次通过ISO9001认证',
                    date:'2022/11/04',
                    content:'2022年11月3日，上海比孚信息科技有限公司再一次顺利通过了ISO9001的认证。 ISO9001证书有效期为3年，从2014年开始，这已经是我们第三个3年了。 在此认证审核的过程中，我们自上而下，从公司，部门到项目，展示了各层级的整体目标及相应的质量标准，实施方案和成果。',
                    url:require("../../assets/image/index/01-1第三个3年.png"),
                    route:'/mobile-news-detail2'
                },
                {
                    index: 3,
                    title:'惊心动魄，可口可乐SCMC的“黄金24小时”',
                    date:'2022/10/26',
                    content:'随着疫情的常态化发展，近年来，越来越多企业选择将IT架构，重要应用、关键数据等核心业务迁移至云端，由于数据对企业发展有着至关重要的作用，因此在选择云平台时，会优先考虑云平台的安全性，提高业务运维的灵活性和弹性。一旦出现突发问题，而运维人员和关键组件无法第一时间到达到场，将会给企业业务带来极大风险。',
                    url:require("../../assets/image/index/05-0可乐24小时.png"),
                    route:'/mobile-news-detail1'
                }
            ],
        };
    },

    mounted() {
    },

    methods: {
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        }
    },
};
</script>

<style lang="less" scoped>
.news-head-line{
    width: 190rem;
    border: 1px solid #F3FAF6;
    margin-top: 11rem;
    box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.2);
}
.news-img{
    width: 100%;
    height: 30rem;
    margin-top: 4rem;
}
.news-img2{
    width: 65.4rem;
    height: auto;
    margin-top: 4rem;
}
.news-box{
    padding-top: 8rem;
    width: 66.5rem;
    margin: auto;
    margin-top: 3rem; 
    .title{
        height: 2.6rem;
        font-size: 2.2rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #333333;
        line-height: 3.9rem;
        text-align: justify;
        margin-top: 2rem;
    }
    .date{
        height: 1.9rem;
        font-size: 1.6rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #999999;
        line-height: 2.4rem;
        text-align: justify;
        margin-top: 2rem;
    }
    .line{
        width: 100%;
        height: 1px;
        border: 1px solid #EDEDED;
        margin-top: 1rem;
    }
    .content{
        width: 100%;
        // height: 50rem;
        font-size: 2.2rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #4F4F4F;
        line-height: 4rem;
        margin-top: 2.4rem;
        text-align: justify;
    }
}
</style>